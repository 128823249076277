export function fenduan(num, limit = 5) {
    num = parseInt(num)
    let f = Math.floor(num / limit)
    if (f < 1) return ['1~' + num]
    let arr = []
    for (let i = 1; i <= limit; i++) {
        if (i === limit) {
            arr.push(
                (i - 1) * f + 1 + '~' + num
            )
        } else
            arr.push(
                (i - 1) * f + 1 + '~' + i * f
            )
    }

    console.info(arr)

    return arr


}


/**
 * 对于给定的数字使用二分查找区段
 * 可不传低位和高位
 */
export function binarySearch(num, numArr, low = 0, height = null) {
    num = parseInt(num)
    if (!height) {
        if (numArr.length - 1) {
            height = numArr.length - 1
        } else {
            return -1;
        }
    }


    if (low > height) {
        return -1;
    }
    let mid = parseInt((height + low) / 2);
    let splitNum = numArr[mid].split('~')
    let min = parseInt(splitNum[0])
    let max = parseInt(splitNum[1])
    if (num >= min && num <= max) {
        return mid;
    } else if (min > num) {
        height = mid - 1;
        return binarySearch(num, numArr, low, height);
    } else if (max < num) {
        low = mid + 1;
        return binarySearch(num, numArr, low, height,);
    } else {
        return -1
    }
}


export function queryURLParams(url) {

    // let pattern = /(\w+)=(\w+)/ig; //定义正则表达式
    //
    // let parames = {}; // 定义参数对象
    //
    // url.replace(pattern, ($, $1, $2) => {
    //
    //     parames[$1] = $2;
    //
    // });
    //
    // return parames;
    let parames = {}; // 定义参数对象
    let tmp = url.split('?')
    if (tmp.length === 1) {
        return parames
    }
    let tmp2 = tmp[1].split('&')
    tmp2.forEach(item => {
        let pattern = /(\w+)=(.+)/ig; //定义正则表达式
        item.replace(pattern, ($, $1, $2) => {
            parames[$1] = $2;
        });
    })
    return parames;

}

export function objToUrlParam(Obj) {
    return Object.keys(Obj).map(key => key + '=' + Obj[key]).join('&');
}

/**
 *   暂时只补全 //开头 后续补充
 * */
export function complteUrl(url) {
    if (url.indexOf('//') === 0) {
        return 'https:' + url
    }
    return url
}

export function strToNumber(strNum) {
    return strNum.replace(/[,\+]/g, '')
}


export function reStr2Num(str) {
    //  1 判断
    str = str.toString()
    let b = /(\d+),?(\d+)?(\.\d+)?/
    let c = str.match(b)

    if (c) {
        str = c[1] + (c[2] || '') + (c[3] || '')
        str = Number(str)
    } else {
        str = 0
    }
    // str 只有两种结果1 数字，2 原始str
    return str
}
