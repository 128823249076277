<!--  -->
<template >
  <div>
    <div class="title">
      <div class="select">
        <span
          style="
            font-size: 14px;
            color: rgba(0, 0, 0, 0.25);
            padding-right: 4px;
          "
          >选择类目：</span
        >
        <el-cascader
          ref="cascaderAddr"
          @change="getCategoryIds"
          style="width: 250px"
          placeholder="请选择类目"
          :options="options"
          :props="categoryprops"
          popper-class="category"
          @click.native="categoryClick"
          collapse-tags
          clearable
        ></el-cascader>
      </div>
      <div class="top">
        <el-button @click="exportData" :disabled="disabled">导出数据</el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.1)"
        :data="tableDataList"
        fit
        style="min-width: 820px"
        :default-sort="{
          prop: 'transactionCountDuring6Months',
          order: 'descending',
        }"
      >
        <el-table-column type="index" label="序号" min-width="10%">
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="店铺"
          min-width="30%
        "
        >
          <div slot-scope="scope" class="shop" @click="goShopPage(scope.row)">
            {{ scope.row.companyName }}
          </div>
        </el-table-column>
        <el-table-column
          prop="transactionCountDuring6Months"
          label="近6个月总订单量"
          sortable
          min-width="15%"
          sort-by="transactionCountDuring6Months1"
        >
        </el-table-column>

        <el-table-column
          prop="transactionGmvDuring6MonthsText"
          label="近6个月总成交额"
          width="auto"
          min-width="15%"
        >
        </el-table-column>
        <el-table-column
          prop="goldSupplierYears"
          label="商家加入年数 "
          min-width="10%"
        >
        </el-table-column>
        <el-table-column prop="reviews" label="评价数" min-width="10%" sortable>
        </el-table-column>
        <el-table-column prop="rate" label="店铺评分" min-width="10%" sortable>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import saveAs from "file-saver";
import dayjs from "dayjs";
import * as Excel from "exceljs";
import { fetchUrl } from "../plugins/csutils";
export default {
  components: {},
  data() {
    return {
      categoryprops: {
        multiple: false,
        checkStrictly: true,
        expandTrigger: "hover",
        label: "categoryCnName",
        value: "categoryId",
      }, //选择类目
      options: null, //类目数据
      tableData: [],
      categoryIds: "",
      injectId: null,
      loading: "",
      disabled: true,
      tableDataList: [],
    };
  },

  created() {
    this.getInjectId();
    this.getOptions();
  },
  methods: {
    getInjectId() {
      this.axios.get("/index/getinject").then((data) => {
        this.injectId = data.data;
      });
    },
    // getInjectId() {
    //   this.injectId = sessionStorage.getItem("lsou_injectId");
    //   console.log("dadasdsa", this.injectId);
    // },
    getShop(i) {
      let url = `https://insights.alibaba.com/openservice/gatewayService?categoryIds=${this.categoryIds}&modelId=10300&pageNo=${i}&pageSize=99`;
      console.log("eeeee", url);
      fetchUrl(url, this.injectId).then((data) => {
        console.log("aaaaaaaa4654", data);
        let tableData = data.data.list;
        console.log("aaaaaaaaa", tableData);
        tableData.map((item) => {
          if (item.transactionCountDuring6Months === "") {
            item.transactionCountDuring6Months = 0;
            item.transactionGmvDuring6Months = 0;
            item.transactionGmvDuring6MonthsText = 0;
            item.transactionCountDuring6Months1 = 0;
          } else {
            item.transactionCountDuring6Months1 = parseInt(
              item.transactionCountDuring6Months.replace(/\,/gi, "")
            );
          }
          if (item.rate === "") {
            item.rate = "0.0";
          }
        });
        this.loading = false;
        this.disabled = false;

        tableData.forEach((item) => {
          this.tableDataList.push(item);
        });
        console.log("aaaaaaaaa798789", this.tableDataList);
        if (tableData.length === 99) {
          this.getShop(i + 1);
        } else {
          return this.tableDataList;
        }
      });
    },
    getOptions() {
      this.axios
        .get("https://api.l-sou.com/static/json/categories.json")
        .then((data) => {
          this.options = data.data;
          console.log("ddddd", this.options);
        });
    },
    //选中类目时
    getCategoryIds(value) {
      this.tableDataList = [];
      console.info("  CheckCategory  ", value);
      console.info(
        "  CheckCategory  this.$refs.cascaderAddr.getCheckedNodes(",
        this.$refs.cascaderAddr.getCheckedNodes()
      );
      this.categoryIds = this.$refs.cascaderAddr.getCheckedNodes()[0].value;
      console.log("dddddd", this.categoryIds);
      this.loading = true;
      this.getShop(1);
    },
    //实现父级可选
    categoryClick(event) {
      let that = this;
      this.$nextTick(() => {
        let d = document.getElementsByClassName("category");
        // console.log(d);
        if (d[0] && !d[0].onclick) {
          d[0].onclick = function (e) {
            // console.log(e);
            if (e.target.className == "el-cascader-node__label") {
              e.target.previousElementSibling.click();
              that.$refs.cascaderAddr.dropDownVisible = false;
            }
          };
        }
      });
    },

    //点击店铺跳转到店铺整体分析页面
    goShopPage(e) {
      window.open(e.companyUrl + "&lsouPageKey=shopGood");
    },

    //导出数据
    exportData() {
      let exportDataList = this.tableDataList;
      let myData = [
        { dataIndex: "companyName", title: "店铺" },
        { dataIndex: "companyUrl", title: "店铺链接" },
        {
          dataIndex: "transactionCountDuring6Months",
          title: "近6个月总订单量",
        },
        {
          dataIndex: "transactionGmvDuring6MonthsText",
          title: "近6个月总成交额",
        },
        {
          dataIndex: "goldSupplierYears",
          title: "商家加入年数",
        },

        {
          dataIndex: "reviews",
          title: "评价数",
        },
        { dataIndex: "rate", title: "店铺评分" },
      ];
      let header = [];
      myData.forEach((item) => {
        header.push(item.title);
      });

      const columns = myData.map((item) => {
        return {
          header: item.title,
          key: item.dataIndex,
        };
      });
      const dataList = exportDataList.map((item) => {
        let res = {};
        res.companyName = item.companyName;
        res.companyUrl = item.companyUrl;
        res.transactionCountDuring6Months = item.transactionCountDuring6Months
          ? item.transactionCountDuring6Months
          : "-";
        res.goldSupplierYears = item.goldSupplierYears
          ? item.goldSupplierYears
          : "-";
        res.transactionGmvDuring6MonthsText =
          item.transactionGmvDuring6MonthsText
            ? item.transactionGmvDuring6MonthsText
            : "-";
        res.rate = item.rate ? item.rate : "-";
        res.reviews = item.reviews ? item.reviews : "-";
        return res;
      });

      this.setStyle(
        header,
        columns,
        dataList,
        "标杆店铺推荐" + dayjs().format("YYYY-MM-DD HH:mm:ss")
      );
    },

   
  },
};
</script>
<style   scoped>
.title {
  min-width: 820px;
  height: 71px;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 80px 0 80px;
}
.el-button {
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-right: 10px;
}
.table {
  margin: 50px 80px 0 80px;
}
.el-table {
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  padding: 20px 24px;
}
.shop:hover {
  cursor: pointer;
  color: #ff6a00;
}
</style>
