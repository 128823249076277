// 只用做content_script 与bk通信工具组件


import {complteUrl} from "./tools";

export function fetchUrl(url,injectId, type='fetch',method='GET',data=null) {
    return new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(injectId, {type: type, url:complteUrl( url),method:method,data:data}, (data) => {
            return resolve(data)
        });
    })
}
export function fetchPostUrl(url,injectId, body, type='fetch',method='POST',data=null) {
    return new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(injectId, {type: type, url:complteUrl( url),method:method,data:data,body:body }, (data) => {
            return resolve(data)
        });
    })
}



