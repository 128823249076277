<template>
  <div class="page">
    <div class="title">插件设置</div>
    <div class="main">
      <div class="box">
        <div class="n1">
          <div class="hlhs pbgcolor"></div>
          <span class="txt">一键分析</span>
        </div>
        <div class="n2">
          <div class="item">
            <el-switch @change="updatesetting($event,'analyse')" v-model="analyse" active-color="#FF6A00" :active-text="analyse?'已开启':'已关闭'"></el-switch>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="n1">
          <div class="hlhs pbgcolor"></div>
          <span class="txt">内嵌功能展示</span>
        </div>
        <div class="n2">
          <div class="n2txt">Alibaba</div>
          <div class="item">
            <div class="txt">商品搜索页</div>
            <div class="list">
              <div class="listitem">
                <el-switch @change="updatesetting($event,'search_showtips_albb')" v-model="search_showtips_albb" active-color="#FF6A00" active-text="产品关键词显示"></el-switch>
              </div>
            </div>
          </div>
          <h1></h1>
          <div class="item">
            <div class="txt">商品详情页</div>
            <div class="list">
              <div class="listitem">
                <el-switch @change="updatesetting($event,'detail_showtips_albb')" v-model="detail_showtips_albb" active-color="#FF6A00" active-text="产品关键词显示"></el-switch>
              </div>
              <!-- <div class="listitem">
                <el-switch @change="updatesetting($event,'detail_supply_albb')" v-model="detail_supply_albb" active-color="#FF6A00" active-text="找货源"></el-switch>
              </div>
              <div class="listitem">
                <el-switch @change="updatesetting($event,'detail_ex_albb')" v-model="detail_ex_albb" active-color="#FF6A00" active-text="汇率转换"></el-switch>
              </div> -->
            </div>
          </div>
        </div>
        <h1></h1>
        <!-- <div class="n2">
          <div class="n2txt">Aliexpress</div>
          <div class="item">
            <div class="txt">商品搜索页</div>
            <div class="list">
              <div class="listitem">
                <el-switch @change="updatesetting($event,'search_imgdown_alex')" v-model="search_imgdown_alex" active-color="#FF6A00" active-text="主图下载"></el-switch>
              </div> -->
              <!-- <div class="listitem">
                <el-switch @change="updatesetting($event,'search_supply_alex')" v-model="search_supply_alex" active-color="#FF6A00" active-text="找货源"></el-switch>
              </div>
              <div class="listitem">
                <el-switch @change="updatesetting($event,'search_ex_alex')" v-model="search_ex_alex" active-color="#FF6A00" active-text="汇率转换"></el-switch>
              </div> -->
              <!--              <div class="listitem">-->
              <!--                <el-switch v-model="yijianfenxi" active-color="#FF6A00" active-text="评论分析"></el-switch>-->
              <!--              </div>-->
              <!--              <div class="listitem">-->
              <!--                <el-switch v-model="yijianfenxi" active-color="#FF6A00" active-text="商品监控"></el-switch>-->
              <!--              </div>-->
            <!-- </div>
          </div>
        </div>
        <h1></h1>
        <div class="n2">
          <div class="n2txt">Shopee</div>
          <div class="item">
            <div class="txt">商品搜索页</div>
            <div class="list">
              <div class="listitem">
                <el-switch @change="updatesetting($event,'search_imgdown_shp')" v-model="search_imgdown_shp" active-color="#FF6A00" active-text="主图下载"></el-switch>
              </div> -->
              <!-- <div class="listitem">
                <el-switch @change="updatesetting($event,'search_supply_shp')" v-model="search_supply_shp" active-color="#FF6A00" active-text="找货源"></el-switch>
              </div>
              <div class="listitem">
                <el-switch @change="updatesetting($event,'search_ex_shp')" v-model="search_ex_shp" active-color="#FF6A00" active-text="汇率转换"></el-switch>
              </div> -->
              <!--              <div class="listitem">-->
              <!--                <el-switch v-model="yijianfenxi" active-color="#FF6A00" active-text="评论分析"></el-switch>-->
              <!--              </div>-->
              <!--              <div class="listitem">-->
              <!--                <el-switch v-model="yijianfenxi" active-color="#FF6A00" active-text="商品监控"></el-switch>-->
              <!--              </div>-->
            <!-- </div>
          </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      analyse: false,//一键分析
      search_showtips_albb: false,//Alibaba商品搜索页：产品关键词显示
      detail_showtips_albb: false,//Alibaba商品详情页：产品关键词显示
      detail_supply_albb: false,//Alibaba商品详情页：找货源
      detail_ex_albb: false,//Alibaba商品详情页：汇率转换
      search_imgdown_alex: false,//Aliexpress商品搜索页：主图下载
      search_supply_alex: false,//Aliexpress商品搜索页：找货源
      search_ex_alex: false,//Aliexpress商品搜索页：汇率转换
      search_imgdown_shp: false,//Shopee商品搜索页：主图下载
      search_supply_shp: false,//Shopee商品搜索页：找货源
      search_ex_shp: false,//Shopee商品搜索页：汇率转换
    }
  },
  mounted() {
    this.getsetting()
  },
  methods: {
    getsetting() {
      this.axios.get('/users/getpluginsetting').then(res => {
        if (res.code === this.global.CODE.SUCCESS) {
          this.analyse = res.data.analyse === 0;
          this.search_showtips_albb = res.data.search_showtips_albb === 0;
          this.detail_showtips_albb = res.data.detail_showtips_albb === 0;
          this.detail_supply_albb = res.data.detail_supply_albb === 0;
          this.detail_ex_albb = res.data.detail_ex_albb === 0;
          this.search_imgdown_alex = res.data.search_imgdown_alex === 0;
          this.search_supply_alex = res.data.search_supply_alex === 0;
          this.search_ex_alex = res.data.search_ex_alex === 0;
          this.search_imgdown_shp = res.data.search_imgdown_shp === 0;
          this.search_supply_shp = res.data.search_supply_shp === 0;
          this.search_ex_shp = res.data.search_ex_shp === 0;
        }
      })
    },
    updatesetting(e, w) {
      let obj = {}
      obj[w] = e ? 0 : 1;
      this.axios.post('/users/updatepluginsetting', obj)
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  color: #313131;
}

.main {
  min-width: 780px;
  background-color: #fff;
  border-radius: 12px;
  margin: 15px 0;
  padding: 28px;
}

.main .box {
  border-radius: 12px;
  box-shadow: 0 2px 5px 0 #ddd;
  margin-top: 30px;
  padding: 20px;
  color: #666;
}

.box .n1 {
  display: flex;
  align-items: center;
}

.box .n1 .hlhs {
  width: 2px;
  height: 16px;
}

.box .n1 .txt {
  margin-left: 10px;
}

.box .n2 .item {
  font-size: 14px;
  margin-top: 20px;
  margin-left: 20px;
}

.box .n2 .n2txt {
  font-size: 14px;
  margin-top: 20px;
}

h1 {
  margin: 20px;
  height: 1px;
  background-color: #eee;
}

.box .n2 .item .list {
  display: flex;
  flex-wrap: wrap;
}

.box .n2 .item .list .listitem {
  margin-top: 20px;
  min-width: 160px;
}

>>> .el-switch__label {
  color: #666;
}
</style>